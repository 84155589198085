import { useMediaQuery } from "@mui/material";
import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  ProductListingSmallBannerData,
  ImageData,
} from "@xxl/frontend-api";
import isEmpty from "lodash/isEmpty";
import { MISSING_PRODUCT_IMAGE_URL } from "../../constants";
import { mobileAndTabletMediaQuery } from "../../utils/xxl-screen";
import { ProductCardContainer } from "../Product/Product.styled";
import { PRODUCT_LISTING_SMALL_BANNER_CLASS_NAME } from "../ProductList/constants";
import { saveCurrentScrollPosition } from "../Search/SearchScrollHelper";
import { INDEX_OF_FIRST_IMAGE } from "./constants";

import {
  Item,
  StyledButton,
  StyledContentWrapper,
  StyledHeading,
  StyledImage,
  StyledImageWrapper,
  StyledLabel,
  StyledLink,
  StyledTextWrapper,
} from "./ProductListingSmallBanner.styled";
import { withErrorBoundary } from "../../utils/WithErrorBoundary/with-error-boundary";
import { handleImageError } from "../../utils/xxl-image";

type SmallBannerProps = {
  content: ProductListingSmallBannerData;
  index: number;
  selectedColumnsNumber?: number;
};

// content-api type has url vs  baseUrl from frontend-api, and seems like both sources are used here. We should consider switching from springboot /info to content-api request (as it's already done in campaign page)
type ImageExtended = (ImageData & { url?: string }) | undefined;

const handleClick = (): void => {
  saveCurrentScrollPosition(document.documentElement.scrollTop);
};

const Content = ({
  content,
  index,
  selectedColumnsNumber,
}: SmallBannerProps): JSX.Element => {
  const { contentImage, label, heading, link, textInverted, mobileImage } =
    content;

  const isMobileOrTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const shouldPrioritizeLoading =
    index === INDEX_OF_FIRST_IMAGE && !isMobileOrTablet;
  const hasMobileImage = !isEmpty(mobileImage);
  const {
    alt = "",
    baseUrl,
    url,
  } = (isMobileOrTablet && hasMobileImage
    ? (mobileImage as ImageExtended)
    : (contentImage as ImageExtended)) ?? {};

  const imageUrl = baseUrl ?? url;

  const urlPrefix =
    imageUrl !== undefined
      ? `${imageUrl}?auto=format&fit=crop`
      : `${MISSING_PRODUCT_IMAGE_URL}?`;
  return (
    <StyledContentWrapper>
      {Boolean(imageUrl) && (
        <StyledImageWrapper>
          <picture data-private={true}>
            <source
              media="(max-width: 767px)"
              srcSet={`${urlPrefix}&w=750&h=450`}
            />
            <source
              media="(min-width: 768px)"
              srcSet={`${urlPrefix}&w=324&h=550`}
            />
            <StyledImage
              alt={alt}
              data-testid="small-banner-image"
              height={421}
              priority={shouldPrioritizeLoading}
              src={`${urlPrefix}&w=750&h=450`}
              width={200}
              onError={handleImageError}
            />
          </picture>
        </StyledImageWrapper>
      )}
      {label !== undefined ||
      heading !== undefined ||
      (link !== undefined && link.url !== undefined) ? (
        <StyledTextWrapper>
          {label !== undefined && (
            <StyledLabel data-testid="small-banner-label">{label}</StyledLabel>
          )}
          {heading !== undefined && (
            <StyledHeading
              data-testid="small-banner-heading"
              columnAmount={selectedColumnsNumber}
            >
              {heading}
            </StyledHeading>
          )}
          {isNotNullOrUndefined(link) && isNotNullOrUndefined(link.url) && (
            <StyledButton
              className="button button--small"
              textInverted={Boolean(textInverted)}
              data-testid="small-banner-button"
            >
              {link.displayName}
            </StyledButton>
          )}
        </StyledTextWrapper>
      ) : null}
    </StyledContentWrapper>
  );
};

const _ProductListingSmallBanner: React.FunctionComponent<SmallBannerProps> = ({
  content,
  index,
  selectedColumnsNumber,
}) => {
  const { backgroundColor, link, textInverted } = content;

  return (
    <li>
      <ProductCardContainer
        columnAmount={selectedColumnsNumber}
        isFocused={false}
        isHoverable={false}
        showHighlightedLabel={false}
        showStoreStock={false}
        showSalesPriceDisclaimer={false}
        showDeliveryTag={false}
      >
        <Item
          textInverted={Boolean(textInverted)}
          backgroundColor={
            backgroundColor !== undefined ? backgroundColor.value : "#efefef"
          }
          data-testid={PRODUCT_LISTING_SMALL_BANNER_CLASS_NAME}
          className={PRODUCT_LISTING_SMALL_BANNER_CLASS_NAME}
        >
          {hasValue(link) && hasValue(link.url) ? (
            <StyledLink
              href={link.url}
              onClick={handleClick}
              data-testid="small-banner-link"
            >
              <Content
                content={content}
                index={index}
                selectedColumnsNumber={selectedColumnsNumber}
              />
            </StyledLink>
          ) : (
            <Content
              content={content}
              index={index}
              selectedColumnsNumber={selectedColumnsNumber}
            />
          )}
        </Item>
      </ProductCardContainer>
    </li>
  );
};

export const ProductListingSmallBanner = withErrorBoundary(
  _ProductListingSmallBanner
);
