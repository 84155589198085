import { hasValue } from "@xxl/common-utils";
import type { OneCardContentComponent as OneCardContentComponentData } from "@xxl/content-api";
import React from "react";
import { OneCardContentComponent } from "./OneCardComponent/OneCardContentComponent";

export const getOneCardContentComponent = (
  componentsData: OneCardContentComponentData[],
  productIndex: number,
  contentIndexRef: React.MutableRefObject<number>,
  numberOfSmallBanners: number,
  selectedColumnsNumber: number
): JSX.Element | null => {
  const nrOfProductsBetweenComponent = 6;
  const showContentAtThisPosition =
    (productIndex + 1 + numberOfSmallBanners) % nrOfProductsBetweenComponent ===
    0;
  const hasRemainingContent = contentIndexRef.current < componentsData.length;
  if (hasRemainingContent && showContentAtThisPosition) {
    const content = componentsData[contentIndexRef.current];
    contentIndexRef.current += 1;

    return hasValue(content) ? (
      <OneCardContentComponent
        content={content}
        selectedColumnsNumber={selectedColumnsNumber}
      />
    ) : null;
  }

  return null;
};
