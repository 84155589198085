import { useProductListParams } from "@/components/ProductListV2/hooks/useProductListParams";
import { useProductList } from "@/hooks/useProductList/useProductList";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { CampaignCategorySelector } from "@/react-components/CategorySelector/Campaign";
import { useScrollRestoration } from "@/react-components/Search/hooks/useScrollRestoration";
import { useMediaQuery } from "@mui/material";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import {
  DigitalCampaignCategoryHeroBannerTextAlignmentEnum,
  type DigitalCampaignCategoryHeroBanner,
  type DigitalCampaignHeroBanner,
} from "@xxl/content-api";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import type { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { Breadcrumbs } from "react-app/src/components/Breadcrumbs";
import CampaignHeroBanner from "react-app/src/components/CampaignHeroBanner/CampaignHeroBanner";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { mobileMediaQuery } from "react-app/src/utils/xxl-screen";
import { BreadcrumbsContainer } from "../../components/ProductDetailsPage/ProductDetailsPage.styles";
import { NewsletterLink } from "../../components/digitalCampaign/NewsletterLink";
import type { DefaultLayoutData, XXLAppData } from "../../global";
import { withPageData } from "../../utils/common-page-data/common-page-data";
import { getNewCampaignPageProps } from "../digitalcampaign/getServerSideProps";
import type { DigitalCampaignPageV2Props } from "../digitalcampaign/types";
import { FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER } from "@/react-components/Search/Constants";
import { withoutShowAllParameter } from "@/react-components/CategorySelector/Campaign/campaign-category-selector-helper";
import { SiteContainer } from "@/components/common/SiteContainer/SiteContainer";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { getAvailabilityAndStoreIdsFromCookieOrDefault } from "@/utils/search-api-utils/availability-and-store-ids";
import SearchResultsProductList from "@/components/PageComponents/search/SearchResultsProductList";
import { getFullPageTitle } from "@/utils/page-helper";
import { SectionWrapper } from "@/components/GridSection/GridSection.styled";
import { GridSection } from "@/components/GridSection/GridSection";

const pickCampaignHeroBanner = (
  categoryPath: string,
  landingPageBanner: DigitalCampaignHeroBanner,
  categoryBanners: DigitalCampaignCategoryHeroBanner[]
) => {
  if (isEmpty(categoryPath)) {
    return landingPageBanner;
  }
  const categoryBanner = categoryBanners.find(
    ({ categoryCode }) => categoryCode === categoryPath
  );
  if (categoryBanner === undefined) {
    return landingPageBanner;
  }
  return categoryBanner;
};

const DigitalCampaignPage: NextPage<
  DigitalCampaignPageV2Props & XXLAppData & DefaultLayoutData
> = ({
  campaignHubUrl,
  campaignIds,
  categoriesOfVisibleProducts,
  categoryBanners,
  categoryPath: categoryPathFromServer,
  environmentData: { frontEndServerUrl },
  gridSections,
  ipaperLink,
  landingPageBannerContent,
  landingPageSelectorLabel,
  numberOfColumnsPerDeviceOnPlp,
  numberOfProductsPerPage,
  bannerPriceData = [],
  productListingSmallBanners,
  productListProps,
  products: productsFromServer,
  shouldDefaultToShowAll,
}) => {
  const { toggle_elevate_cluster_landing_page } =
    useSharedData().data.featureToggles;
  const { elevateApi, elevateClusterApi } = useApiClients();
  const api = toggle_elevate_cluster_landing_page
    ? elevateClusterApi
    : elevateApi;

  const { t } = useTranslations();
  const pageTitle = getFullPageTitle(landingPageBannerContent.heroTitle, t);
  const router = useRouter();
  const [selectedHeroBanner, setSelectedHeroBanner] = useState<
    DigitalCampaignHeroBanner | DigitalCampaignCategoryHeroBanner
  >(
    pickCampaignHeroBanner(
      categoryPathFromServer[0],
      landingPageBannerContent,
      categoryBanners
    )
  );
  const isMobile = useMediaQuery(mobileMediaQuery);
  const [isAdditionalViewSelected, setIsAdditionalViewSelected] = useState(
    shouldDefaultToShowAll
      ? false
      : isNotNullOrUndefined(landingPageSelectorLabel) &&
          categoryPathFromServer.filter(withoutShowAllParameter).length === 0
  );
  const [categoryPath, setCategoryPath] = useState<string[]>(
    shouldDefaultToShowAll
      ? [FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER]
      : categoryPathFromServer
  );
  const categoryId =
    categoryPath.filter(withoutShowAllParameter).at(-1) ?? null;
  const [canonical] = useState(
    `${frontEndServerUrl}${router.asPath.split("?")[0].replace("/v2/", "/")}`
  );
  useScrollRestoration();
  const breadcrumbsData = [
    { name: t("breadcrumbs.home"), url: "/" },
    { name: t("breadcrumbs.campaignhub"), url: campaignHubUrl },
    { name: "Mega sale", url: "" },
  ];
  const {
    selectedFilters,
    sortOrderData,
    storeIds: storeIdsFromServer,
  } = productListProps;
  const { availability, storeIds } =
    getAvailabilityAndStoreIdsFromCookieOrDefault(storeIdsFromServer);
  const {
    facets,
    fetchDataClientSide,
    isFetchingProducts,
    products,
    totalHits,
  } = useProductList({
    api,
    campaignIds,
    facetsFromServer: productListProps.facets ?? [],
    isDiscountCategoryCode: false,
    longTailFacets: null,
    longTailPattern: null,
    numberOfProductsPerPage,
    pageType: "landing",
    productsFromServer,
    totalHitsFromServer: productListProps.totalHits,
    stores: productListProps.storesData,
    subType: "campaign",
  });
  const { setCategoryPath: setCategoryPathOnUrl } = useProductListParams(true);

  useEffect(() => {
    // Campaign pages may still have `?showAll=true` which now is replaced with `categoryPath=showAll`
    const params = new URLSearchParams(window.location.search);
    if (params.has(FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER)) {
      setCategoryPathOnUrl([FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER]);
    }
  }, [setCategoryPathOnUrl]);

  const {
    backgroundColor,
    countdownTimerSettings,
    desktopImage,
    disclaimerInformation,
    heroDescription,
    heroLabel,
    heroTitle = "",
    imageOverlay = false,
    mobileImage,
    textAlignment = DigitalCampaignCategoryHeroBannerTextAlignmentEnum.center,
    textColor = "black",
  } = selectedHeroBanner;

  const onCategoryChange = useCallback(
    (path: string[], isAdditionalView = false) => {
      const shouldShowAll = !isAdditionalView && isEmpty(path);
      setIsAdditionalViewSelected(isAdditionalView);
      const newPath = shouldShowAll
        ? path
        : path.filter(withoutShowAllParameter);
      setCategoryPath((state) => (isEqual(state, newPath) ? state : newPath)); // Return same reference if is equal
      setCategoryPathOnUrl(
        shouldShowAll ? [FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER] : newPath
      );

      const selectedBanner = pickCampaignHeroBanner(
        path[0] ?? "",
        landingPageBannerContent,
        categoryBanners
      );
      setSelectedHeroBanner(selectedBanner);
    },
    [
      categoryBanners,
      landingPageBannerContent,
      setCategoryPath,
      setCategoryPathOnUrl,
    ]
  );

  return (
    <>
      <Head>
        <link rel="canonical" href={canonical} />
        <meta name="robots" content={"noindex"} />
        <title>{pageTitle}</title>
      </Head>
      <div className="container">
        <BreadcrumbsContainer>
          <Breadcrumbs
            breadcrumbs={breadcrumbsData}
            homeUrl={frontEndServerUrl}
            shouldUseNextLink={true}
          />
        </BreadcrumbsContainer>
      </div>
      <CampaignHeroBanner
        title={heroTitle}
        textColor={textColor}
        textAlignment={textAlignment}
        imageOverlay={imageOverlay}
        label={heroLabel}
        description={heroDescription}
        mobileImage={mobileImage}
        desktopImage={desktopImage}
        countdownTimerSettings={countdownTimerSettings}
        backgroundColor={backgroundColor?.value}
        ipaperLink={ipaperLink}
        disclaimerInformation={disclaimerInformation}
      />
      <SectionWrapper className="component-standard-spacing">
        <>
          <section className="container">
            {ipaperLink !== undefined && isMobile && (
              <NewsletterLink url={ipaperLink} />
            )}
            <CampaignCategorySelector
              categories={categoriesOfVisibleProducts}
              categoryPath={categoryPath}
              additionalViewSelectorLabel={landingPageSelectorLabel}
              onCategoryChange={onCategoryChange}
            />
          </section>
          {isAdditionalViewSelected ? (
            gridSections.map(
              (
                {
                  gridContainers,
                  sectionName,
                  visibleContainersCount,
                  buttonUrl,
                  buttonText,
                },
                index
              ) =>
                gridContainers !== undefined && (
                  <GridSection
                    key={index}
                    name={sectionName}
                    gridContainers={gridContainers}
                    visibleContainersCount={visibleContainersCount ?? undefined}
                    priceData={bannerPriceData}
                    buttonText={buttonText ?? undefined}
                    buttonUrl={buttonUrl ?? null}
                  />
                )
            )
          ) : (
            <SiteContainer>
              <SearchResultsProductList
                autoCorrect={null}
                availability={availability}
                categoryId={categoryId}
                categoryPath={categoryPath}
                facets={facets}
                fetchDataClientSide={fetchDataClientSide}
                isFetchingProducts={isFetchingProducts}
                longTailFacets={null}
                longTailPattern={null}
                numberOfColumnsPerDeviceOnPlp={numberOfColumnsPerDeviceOnPlp}
                productListingSmallBanner={productListingSmallBanners}
                products={products}
                selectedFilters={selectedFilters}
                sortOrderData={sortOrderData}
                storeIds={storeIds}
                storesData={productListProps.storesData}
                totalHits={totalHits}
              />
            </SiteContainer>
          )}
          <section className="container">
            <CampaignCategorySelector
              categories={categoriesOfVisibleProducts}
              categoryPath={categoryPath}
              additionalViewSelectorLabel={landingPageSelectorLabel}
              onCategoryChange={onCategoryChange}
            />
          </section>
        </>
      </SectionWrapper>
    </>
  );
};

export default DigitalCampaignPage;

export const getServerSideProps = withPageData<boolean>(
  getNewCampaignPageProps
);
