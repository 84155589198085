import type { Store } from "@xxl/content-api";
import { Store as StoreIcon } from "@xxl/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { StyledCheckbox } from "../../../styled";
import { getPreferredStoresCookie } from "../../../utils/Cookie";
import { XxlStack } from "../../Common/XxlStack";
import { Icon } from "../../Icon";
import { AVAILABILITY } from "../../Search/SearchFetchProductsHelper.types";
import { Text } from "../../Text";
import type { TInput_ID } from "./AvailabilitySelector.helper";
import {
  INPUT_ID,
  availabilityInputs,
  getMobileSelectedStoreLabel,
  getStoreCheckboxLabel,
} from "./AvailabilitySelector.helper";
import {
  AvailabilityFieldset,
  AvailabilityForm,
  Container,
  EditButtonContainer,
  StoreName,
  StoreNameWrapper,
} from "./AvailabilitySelector.styled";
import type { StoreWithId } from "./DialogStoreSelect";
import { DialogStoreSelect } from "./DialogStoreSelect/DialogStoreSelect";
import { EditSelectedRegionButton } from "./EditSelectedRegionButton";
import { EditSelectedStoresButton } from "./EditSelectedStoresButton";
import {
  getStoreListItems,
  getStoreRegions,
} from "./MultipleAvailabilitySelector.helper";
import { SelectedStoreInfoMessage } from "./SelectedStoreInfoMessage";
import { useClient } from "react-app/src/hooks/useClient/useClient";
import { isEmpty } from "@xxl/common-utils";

export type viewStates = "STORES" | "REGION" | null;

type OnUpdateFiltersProps = {
  stores: Store[];
  availability: AVAILABILITY[];
};

export type MultipleAvailabilitySelectorProps = {
  selectedAvailability: AVAILABILITY[];
  selectedStoreIds: string[];
  onChange: (
    stores: Store[],
    availability: AVAILABILITY[],
    autoCorrect?: string
  ) => Promise<void> | void;
  storesData: StoreWithId[];
  isEditEnabled?: boolean;
};

const MultipleAvailabilitySelector = ({
  isEditEnabled = true,
  onChange,
  selectedAvailability,
  selectedStoreIds,
  storesData,
}: MultipleAvailabilitySelectorProps) => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const { t } = useTranslations();
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleIsDialogOpen = () => setIsDialogOpen(!isDialogOpen);
  const [selectedStoreIdsInternal, setSelectedStoreIdsInternal] =
    useState(selectedStoreIds);
  const [filterKey, setFilterKey] = useState("");
  const handleFilterKeyChange = (value: string) => setFilterKey(value);
  const [viewState, setViewState] = useState<viewStates>(null);
  const storeRegions = getStoreRegions(storesData);
  const isStoresLoading = storesData.length === 0;
  const isClient = useClient();

  useEffect(() => {
    setSelectedStores(
      storesData.filter((store) => selectedStoreIds.includes(store.id))
    );
    if (isEmpty(selectedStoreIdsInternal)) {
      setSelectedStoreIdsInternal(selectedStoreIds);
    }
  }, [storesData, selectedStoreIds]);

  const getSelectedRegion = () => {
    const lastSelectedStoreId = getPreferredStoresCookie()?.ids.pop();
    if (lastSelectedStoreId === undefined) return;
    return Object.values(storeRegions).find((region) =>
      region.regionIds.includes(lastSelectedStoreId)
    );
  };

  const getSelectedRegionName = () => {
    const selectedRegion = getSelectedRegion();
    if (selectedRegion === undefined) return;
    return selectedRegion.name;
  };

  const getRegionIds = () => {
    const selectedRegion = getSelectedRegion();
    if (selectedRegion === undefined) return [];
    return [...selectedRegion.regionIds, selectedRegion.id];
  };

  const isOnlyRegionSelected = () => {
    const selectedRegion = getSelectedRegion();
    if (selectedRegion === undefined) return false;
    if (
      selectedRegion.regionIds.length + 1 ===
      selectedStoreIdsInternal.length
    ) {
      return true;
    }
    return false;
  };

  const isRegionSelectedOnModalOpen = (storeIds: string[]) => {
    const selectedRegion = getSelectedRegion();
    if (selectedRegion === undefined) return false;
    if (selectedRegion.regionIds.length === storeIds.length) {
      return true;
    }
    return false;
  };

  const storeListItems = [...getStoreListItems(storesData), ...storeRegions];

  const getCheckboxLabel = (checkboxId: TInput_ID) => {
    switch (checkboxId) {
      case INPUT_ID.ONLINE:
        return t("general.available.online");
      case INPUT_ID.STORE:
        return (
          <XxlStack direction="row" justifyContent="center" alignItems="center">
            {!isMobile && <StoreIcon />}
            {isClient
              ? getStoreCheckboxLabel({
                  selectedStores: storesData.reduce((matchingStores, store) => {
                    if (selectedStoreIds.includes(store.id)) {
                      matchingStores.push(store);
                    }

                    return matchingStores;
                  }, [] as StoreWithId[]),
                  t,
                })
              : t("general.available.in.store")}
          </XxlStack>
        );
      default:
        return t("general.available.in.store");
    }
  };

  const onUpdateSelection = useCallback(
    ({ stores, availability }: OnUpdateFiltersProps) => {
      if (stores.length === 0 && availability.length === 0) {
        return;
      }
      const ids: string[] = stores.reduce(
        (acc: string[], store: Store) =>
          store.id === undefined ? acc : [...acc, store.id],
        []
      );

      const hasAvailabilityChange =
        availability.length !== selectedAvailability.length ||
        !availability.every(
          (entry, idx) => entry === selectedAvailability[idx]
        );

      const hasStoreChange =
        ids.length !== selectedStoreIds.length ||
        !ids.every((entry, idx) => entry === selectedStoreIds[idx]);

      const hasNoChange = !hasAvailabilityChange && !hasStoreChange;

      if (hasNoChange) {
        return;
      }

      setSelectedStores(stores);
      void onChange(stores, availability);
    },
    [onChange, selectedAvailability, selectedStores, storesData]
  );

  const onSelectStores = (stores: Store[] | null) => {
    if (stores === null) {
      setIsDialogOpen(true);
      return;
    }
    const updatedAvailability = selectedAvailability.includes(
      AVAILABILITY.STORE
    )
      ? selectedAvailability
      : [...selectedAvailability, AVAILABILITY.STORE];
    onUpdateSelection({ stores, availability: updatedAvailability });
    setIsDialogOpen(false);
  };

  const onSelectOnline = () => {
    const updatedAvailability = selectedAvailability.includes(
      AVAILABILITY.ONLINE
    )
      ? selectedAvailability
      : [...selectedAvailability, AVAILABILITY.ONLINE];
    onUpdateSelection({
      availability: updatedAvailability,
      stores: selectedStores,
    });
  };

  const handleClose = (updated: boolean) => {
    const storesAreSavedButNotSelected =
      !selectedAvailability.includes(AVAILABILITY.STORE) &&
      selectedStoreIdsInternal.length > 0;

    if (updated || storesAreSavedButNotSelected) {
      const selectedStoreList = [];
      for (const id of selectedStoreIdsInternal) {
        const store = storesData.find((s) => s.id === id);
        if (store !== undefined) {
          selectedStoreList.push(store);
        }
      }
      onSelectStores(selectedStoreList);
    }
    setViewState(null);
    toggleIsDialogOpen();
  };

  const onAvailabilityCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const inputId = e.target.id;
    const inputAvailabilityValue = e.target.value as AVAILABILITY;

    if (!checked) {
      if (selectedAvailability.length === 1) {
        return;
      }

      const updatedAvailability = selectedAvailability.filter(
        (a) => a !== inputAvailabilityValue
      );

      void onChange(selectedStores, updatedAvailability);
      return;
    }

    if (inputId === INPUT_ID.ONLINE) {
      onSelectOnline();
    } else {
      const storesToUpdate = selectedStores.length > 0 ? selectedStores : null;
      onSelectStores(storesToUpdate);
    }
  };

  const handleOnRegionClick = () => {
    setSelectedStoreIdsInternal([
      ...new Set([...getRegionIds(), ...selectedStoreIdsInternal]),
    ]);
    setFilterKey(getSelectedRegionName() ?? "");
    setViewState("REGION");
    toggleIsDialogOpen();
  };

  const handleEditBtnClick = () => {
    const currentStoresIds = selectedStoreIdsInternal;
    const isRegionSelected = isRegionSelectedOnModalOpen(currentStoresIds);

    if (isRegionSelected) {
      setSelectedStoreIdsInternal([
        ...new Set([...getRegionIds(), ...selectedStoreIdsInternal]),
      ]);
    } else {
      setSelectedStoreIdsInternal([...new Set([...currentStoresIds])]);
    }

    setFilterKey("");
    setViewState("STORES");
    toggleIsDialogOpen();
  };

  const shouldShowEditButton =
    isClient &&
    isEditEnabled &&
    (selectedStores.length > 0 ||
      selectedAvailability.includes(AVAILABILITY.STORE));

  const mobileSelectedStoreLabel = getMobileSelectedStoreLabel(
    t,
    selectedStores,
    isOnlyRegionSelected(),
    getSelectedRegionName()
  );

  return (
    <Container>
      <AvailabilityForm>
        <XxlStack my={"12px"}>
          <AvailabilityFieldset>
            {availabilityInputs.map(({ id, value }) => (
              <StyledCheckbox
                isDisabled={!isClient}
                key={`availability-checkbox-${id}`}
                name="availability-checkbox"
                id={id}
                value={value}
                checked={
                  isClient ? selectedAvailability.includes(value) : false
                }
                handleChange={onAvailabilityCheckboxChange}
                label={getCheckboxLabel(id)}
              />
            ))}
          </AvailabilityFieldset>
        </XxlStack>
        {isMobile && selectedAvailability.includes(AVAILABILITY.STORE) && (
          <StoreNameWrapper onClick={handleEditBtnClick}>
            <StoreName isBold={true}>
              <Icon name="Store" />
              {mobileSelectedStoreLabel}
            </StoreName>
            <Text
              data-testid="edit-store-selector"
              size="small"
              fontFamily="medium"
              underline={true}
            >
              {t("general.edit")}
            </Text>
          </StoreNameWrapper>
        )}
        <XxlStack mt={"12px"} />
        <SelectedStoreInfoMessage
          isMobile={isMobile}
          addTopMargin={selectedStores.length === 0}
        />
      </AvailabilityForm>
      <EditButtonContainer>
        {shouldShowEditButton && !isMobile && (
          <EditSelectedStoresButton onClick={handleEditBtnClick}>
            {t("general.edit")}
          </EditSelectedStoresButton>
        )}
        {isClient && ( // Fix hydration error due to regionName being different on server and client
          <EditSelectedRegionButton
            isMobile={isMobile}
            onClick={handleOnRegionClick}
            regionName={getSelectedRegionName()}
          />
        )}
        <SelectedStoreInfoMessage isMobile={isMobile} />
      </EditButtonContainer>
      <DialogStoreSelect
        filterKey={filterKey}
        isLoading={isStoresLoading}
        isOpen={isDialogOpen}
        onClose={handleClose}
        onFilterKeyChange={handleFilterKeyChange}
        onStoreSelect={setSelectedStoreIdsInternal}
        selectedRegion={getSelectedRegion()}
        selectedStoreIds={selectedStoreIdsInternal}
        storeListItems={storeListItems}
        storeRegions={storeRegions}
        stores={storesData}
        viewState={viewState}
      />
    </Container>
  );
};

export { MultipleAvailabilitySelector };
